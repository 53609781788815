import React, { useEffect } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import HeroBanner from "../components/atoms/HeroBanner";
import ColorBanner from "../components/atoms/ColorBanner";
import Author from "../components/atoms/Author";
import Quote from "../components/atoms/Quote";
import OurTeam from "../components/ogranisms/OurTeam";
import Head from "../components/ogranisms/head";
import PresentLike from "../components/molecules/presentLike";
import ShowRooms from "../components/molecules/ShowRooms";
import AOS from "aos";
import "aos/dist/aos.css";
import * as styles from "./styles.module.scss";
import FlexBanner from "../components/atoms/FlexBanner/FlexBanner";
import joneimg from "../images/Jone-quote.jpg";
import { connectlist2 } from "../constants/connectData";
import Unique from "../components/molecules/hr/unique";
import FindJob from "../components/molecules/hr/findJob";
import EducationPartner from "../components/molecules/hr/educationpartner";
import Institutions from "../components/molecules/hr/institutions";
import { eduList } from "../constants/educationList";
import imgcenter from "../images/Puting-dialog.jpg";
import { Link } from "gatsby";
import { aboutSections } from "../constants/home";
import Explore from "../components/atoms/explore";
import LeftRight from "../components/molecules/LeftRight/LeftRight";
import FreeVideos from "../components/molecules/FreeVideos/FreeVideos";
import MainSlider from "../components/molecules/MainSlider/MainSlider";
import { aboutSlider } from "../constants/aboutUsValues";
import PdfSection from "../components/atoms/PdfSection/PdfSection";
import ModulesArticle from "../components/ogranisms/ModulesArticle/ModulesArticle";
import SocialMedia from "../components/molecules/SocialMedia/SocialMedia";
import FullscreenGeneral from "../components/atoms/Fullscreen/FullscreenGeneral";

function AboutUs() {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  return (
    <>
      <FullscreenGeneral />
      <Head
        title="Welcome to the WiZR workspace"
        description="Welcome to the WiZR workspace
        The place to organize, present, share and collaborate. Cultivating what matters the most to you."
        ogTitle="About us"
      />
      <Header />
      <main>
      {aboutSections.slice(0, 3).map((d, i) => {
        return <LeftRight key={i} {...d} />;
      })}
      <Explore mwimage extraClass="aboutSection"  title="How We Socialize" description="With 10 countries and keep growing we work virtually but also in-person through our studios. We want to me masters of remote work through the tools we create and use and how we work together. At the same time we also want to be great where in-person works the best. Combining the two is the making of something special." />
      {aboutSections.slice(3,5).map((d, i) => {
        return <LeftRight key={i} {...d} />;
      })}
      <FreeVideos />
      {aboutSections.slice(5).map((d, i) => {
        return <LeftRight key={i} {...d} />;
      })}
         <PdfSection
          extraClass="brightversion2"
          title="What inspired us "
        />
        <ModulesArticle />
        <SocialMedia />
      <MainSlider extraClass="teamSlider" slideWidth="85%" height="44vh" title="From Pixels to Handshakes" data={aboutSlider} slidesNumber={1}/>
        {/* {/* <Header /> */}
        <Footer />
      </main>
    </>
  );
}

export default AboutUs;
